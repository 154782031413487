import { cargoStatusesWithHiddenFormFields } from '../../constants'
import { CargoStatus } from '../../content/select-n-autocomplete-options'
import { FormInput, Type } from '../cargo-modal/add-cargo-modal'

const sharedRequiredKeys: (keyof FormInput)[] = [
  'tradingType',
  'privateAndConfidential',
  'dateQuoted',
  'pic',
  'status',
  'cargoGrade',
  'pool',
  'laycanFrom',
  'charterer',
]

const spotRequiredKeys: (keyof FormInput)[] = ['quantity', 'quantityType']

function getRequiredHidableFormFields(
  status?: CargoStatus,
): (keyof FormInput)[] {
  if (status === CargoStatus.Withdrawn) {
    return []
  }
  return ['vessel']
}

export function getRequiredKeys(type?: Type, status?: CargoStatus) {
  const fields =
    type === 'T/C'
      ? sharedRequiredKeys
      : [...sharedRequiredKeys, ...spotRequiredKeys]
  return !status || cargoStatusesWithHiddenFormFields.includes(status)
    ? fields
    : [...fields, ...getRequiredHidableFormFields(status)]
}

export function getMissingRequiredFields(input: FormInput) {
  const requiredKeys = getRequiredKeys(input.tradingType, input.status)

  const missingKeys = (requiredKeys as Array<keyof FormInput>).filter(
    (key) =>
      input[key] === undefined ||
      input[key] === null ||
      input[key] === '' ||
      (Array.isArray(input[key]) && input[key].length === 0),
  )

  return missingKeys
}
